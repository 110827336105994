import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Install/IN-6001-8001/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera-in-6001-hd--in-8001-fhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera-in-6001-hd--in-8001-fhd",
        "aria-label": "add a new p2p camera in 6001 hd  in 8001 fhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera (`}<em parentName="h3">{`IN-6001 HD & IN-8001 FHD`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/81315/Android_Wizard_Installation_8001_6001_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACa0lEQVQ4y42Sy27TQBSGvUX0IhZVlDRxEidVYzue+4zHY3tcOwmkaWLSqKoqNlX7CGwBIV6CPSy64GF4BVix4iGQx62ouoB8OpoZjfSf8885Y7Xb7Uajsb+///yBwWDQ6XRarcNO1xkOh67rHhwc7Ozs7O7u7u3t9R3Htu1W67DnHFmj0Wg4HOZ5Tgjp9XqO43Qr7K5z1MTz4+PRYOAopYqiSJJEa805t2272+s36dLyPM/3fQBAEAT+E8bA933P8yCEQgjOuTAghFzX9YPAwgZkwE9BxCCEyLJMG7Isi+OYMUYpsdAD0PD4AABQSl1cXFxeXtbr1dXVZrMpy/L6+no6ndZibApQE/fUWQghq9WqLMv1er3ZbM7Pz18b1uu1lNJCECIwTuIo06lO4tpYqjVGEAEghFgsFmVZLpfLx+Kzs7M0TS1MOc5OiT6l6ZzohRCCcY4RBEwBGhGMzPMq8jyfzWZSSowrp5RSi3IRT05VMVfZVOfTNE2llAgCSENI2H0DTAuCIBiPxxBCjHF9adEwkpOVKEqpiyjkKo6ViglGAZWAVlkoY5xX5RmrtjTVKo6jKKrEQvD5q1mWxjIMpYyiKKqMIQSIqCojxCmB8iRQLxkloZSTIp8WJ1prhJBV9QvAJ9SzM4GrgYqIhAmlNJKhSHKUzJNYYYz/zvkfUIIZJeZAmExIPOG8MrWNGGLCCBMQE4gpoTwMRW1uq8pkfMTcLnF7xO1Rt0u8vnnR/8UYg3F//b5x+82++dK9/dq8uWu/+VxPcRux17y5e/bhd+vjj86nn8/f/Xrx9jukAkGwle3qC0NQg6AJwx/QBt5e3lBAMAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/e4706/Android_Wizard_Installation_8001_6001_d01.avif 230w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/d1af7/Android_Wizard_Installation_8001_6001_d01.avif 460w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/7f308/Android_Wizard_Installation_8001_6001_d01.avif 920w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/e1c99/Android_Wizard_Installation_8001_6001_d01.avif 1380w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/6e1e4/Android_Wizard_Installation_8001_6001_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/a0b58/Android_Wizard_Installation_8001_6001_d01.webp 230w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/bc10c/Android_Wizard_Installation_8001_6001_d01.webp 460w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/966d8/Android_Wizard_Installation_8001_6001_d01.webp 920w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/445df/Android_Wizard_Installation_8001_6001_d01.webp 1380w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/87a8c/Android_Wizard_Installation_8001_6001_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/81c8e/Android_Wizard_Installation_8001_6001_d01.png 230w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/08a84/Android_Wizard_Installation_8001_6001_d01.png 460w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/c0255/Android_Wizard_Installation_8001_6001_d01.png 920w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/b1001/Android_Wizard_Installation_8001_6001_d01.png 1380w", "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/81315/Android_Wizard_Installation_8001_6001_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b2b9bf5ddc39f7ff354be0ac47aed2c3/c0255/Android_Wizard_Installation_8001_6001_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Installation Wizard`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can find the `}<strong parentName="li">{`QR Code`}</strong>{` in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
        }}>{`WebUI`}</a>{` (A), printed on the label of your camera (B) or on the package.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/81315/Android_Wizard_Installation_8001_6001_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACr0lEQVQ4y3XSXU/aYBQH8H4AjbeosSUSY0xGALEClrYMDKXXQ+PFfIuUxIvhBsIqKMssYCkqfXn6Sum8cJfezA+wbJ9l+xh7CSWaJXS/q+fm5Pyfcw60uLiIIAiKorFYDIZhn883Nzc3Pz+/sLAAw/DKysrS0pLP55uZmZmenp6amkIQBIbh2dlZv98P5fOvtra2dnd39/b2dnZ2tre38/k8TdOpl6n12Pry8nIgECBJEsMwBEECgYD/CYIgkK7rlsu2B44zvLu7cxzHtEzD1PtiP51Ox+PxSCQSDAZfuIL/gCRJAi71iaZpqqqapgkAoGmaJEkURcPhcGQCpCiKpqkAAO2JruuaphmGAYBCUdmNjY3V1dVQKBSeAMmyrGmabduO65PLcRzLshRFyeVyJElmMploNDpZDwkCL4qiLMuGYZimaVnWwOU4DgCAoigMw9bW1sJeII77KAi8IPCSJI1jG67hcKiqKkVRBEGgKOodu9E4b7W4Tqd9c3M9npbuGhfTNJ1MJtPptHfsZrPJ81fdLt/rCQAoz2MbFWsqlRvF/u/Arns93dBkWRLFvqLI7p5G7Z87EwSBYVgkEvEofnh4+Pnzx+PjF1Hsi2LfrRwlt20bAJDL5XAcJwjCszl0//n+959f375/7XTa3S6vKLL7c3UwGIxXheN4yOXRuVo9tYcDVQNci2u3W4LQvb29GQ9MkiSKohKJBEmS0Wh08sig4+NjlmU57rJer7Mse3FxznGXACimaYhiP5vNxmKxRCLh/WeGKVQqlXq9fnJSKpffVcrlavW01eKu+E6n0x4fSTgc9o5dYI5KpTfl8ttarXZ2xlZrpyz7nmXZZvNDo3G+uZlJJpOpVMp7zwcH+4XCEVMsFItMscgwzOh9eLhfYF6XTvYzmRSOE/F4LOLlLy84gUO8n4K+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c159ed4166a91a87ce7fa6f5ade9922/e4706/Android_Wizard_Installation_8001_6001_d02.avif 230w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/d1af7/Android_Wizard_Installation_8001_6001_d02.avif 460w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/7f308/Android_Wizard_Installation_8001_6001_d02.avif 920w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/e1c99/Android_Wizard_Installation_8001_6001_d02.avif 1380w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/6e1e4/Android_Wizard_Installation_8001_6001_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c159ed4166a91a87ce7fa6f5ade9922/a0b58/Android_Wizard_Installation_8001_6001_d02.webp 230w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/bc10c/Android_Wizard_Installation_8001_6001_d02.webp 460w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/966d8/Android_Wizard_Installation_8001_6001_d02.webp 920w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/445df/Android_Wizard_Installation_8001_6001_d02.webp 1380w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/87a8c/Android_Wizard_Installation_8001_6001_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c159ed4166a91a87ce7fa6f5ade9922/81c8e/Android_Wizard_Installation_8001_6001_d02.png 230w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/08a84/Android_Wizard_Installation_8001_6001_d02.png 460w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/c0255/Android_Wizard_Installation_8001_6001_d02.png 920w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/b1001/Android_Wizard_Installation_8001_6001_d02.png 1380w", "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/81315/Android_Wizard_Installation_8001_6001_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c159ed4166a91a87ce7fa6f5ade9922/c0255/Android_Wizard_Installation_8001_6001_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Select your camera model, e.g. `}<strong parentName="li">{`IN-8001FullHD`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b6f902d4603cb6981ebf2a4024720b6/81315/Android_Wizard_Installation_8001_6001_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPElEQVQ4y42Sz3LTMBDGfXZ6TZvS1HbsSWjcWNJKK9nxTGzHISEhf6ad0MK9wIE3gAOcOfEWcOmNK2/SK8NzMLZiEzIDw++gkVb+tLvf2vB93/O84+Nj0zSPjo4ajYbnee12u3XaOnfcbrfb7/ebzaZpmo0Sx3Esy3p0duZ4XaPX63mel2UZAFiW1el0LNt2bMv2ei2YP764cF03TTNEtG3bdV27wLKdzqlYGZclhJAgCKSUiDgYDHTwckD2by8PCIgBJYwxABiVcM7hT1gJHMIMfcE5V0ohIgAgItuDVuj9fsTQByllkiRxHGNJrQSAg0L2gzuxUmo0Gg2HQyFELaaUCoFJkuZ5Pp1OJyV5ns9ms3GeK6V2ZYdhiIicc0RUStV9ltlZkiRZlmlH0hIUnFHyW6xz6rUSU8plEM90o1UQCCFERAHGO7EekpRy3zBKiQqj7e3L7XZ7dXW1WCzW6/XT+ZxROghzP5oZtQFSSiEEAEgp655R8Mk4nYzTOB7qdznnu2a0YXpUumApZXWtaxU+jvrhhADWPgAABUE5GrUvhBD9xP6EpQrX18/X1zeLZ8vVcrlarTabzTjLfJX7w3mVWUiOEoQEgRyL/a5sFE8meZYmegpKqWIuQhCuiIgqt1k/ol1FeiHpRbQbsX6dPwgCQsjBrwaMAqNaTO2bTyd39/arL87rr627+/MXn4sPGNUdsr+wE5+8+Wa+/2l9fDj78GC++9F8+50B1+J/UBlGCaNFeUWFNCiO/8EvE2zWvLsSNhEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b6f902d4603cb6981ebf2a4024720b6/e4706/Android_Wizard_Installation_8001_6001_d03.avif 230w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/d1af7/Android_Wizard_Installation_8001_6001_d03.avif 460w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/7f308/Android_Wizard_Installation_8001_6001_d03.avif 920w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/e1c99/Android_Wizard_Installation_8001_6001_d03.avif 1380w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/6e1e4/Android_Wizard_Installation_8001_6001_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b6f902d4603cb6981ebf2a4024720b6/a0b58/Android_Wizard_Installation_8001_6001_d03.webp 230w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/bc10c/Android_Wizard_Installation_8001_6001_d03.webp 460w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/966d8/Android_Wizard_Installation_8001_6001_d03.webp 920w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/445df/Android_Wizard_Installation_8001_6001_d03.webp 1380w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/87a8c/Android_Wizard_Installation_8001_6001_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b6f902d4603cb6981ebf2a4024720b6/81c8e/Android_Wizard_Installation_8001_6001_d03.png 230w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/08a84/Android_Wizard_Installation_8001_6001_d03.png 460w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/c0255/Android_Wizard_Installation_8001_6001_d03.png 920w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/b1001/Android_Wizard_Installation_8001_6001_d03.png 1380w", "/en/static/0b6f902d4603cb6981ebf2a4024720b6/81315/Android_Wizard_Installation_8001_6001_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b6f902d4603cb6981ebf2a4024720b6/c0255/Android_Wizard_Installation_8001_6001_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Select wether your camera should be connected via `}<strong parentName="li">{`WiFi`}</strong>{` or if you will be using an Ethernet cable.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c7957f47104579023448d5a2a247479/81315/Android_Wizard_Installation_8001_6001_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3ElEQVQ4y2WSTW8TVxSGzZbYIVKjgHFmrokTj8nM3HO/Zuz7MR92bHkRx3FiS0Fu3U13QVUlUKVuChIsWLGtKqSqa/4ASLRSd93AigW/gC3iLyDPdQKIdzEa6b3PPec955Y8z9vd3d3a2iqXy5VKZWNjw3VdhND2tlPdrrdaLetWKpVyuby2toYQcl23Wq2ihleycLfbBYB6vb6zs4PqdeQ6qNGsseFe09vb202SJIoihNDSLeh6/dZNflgKgoAQkqZpkiRKKa21UipNU0KI7/tBEABAXCiKIlaIc04pDYOgxBhLChljsizL8zxJkrwQ5xwAtNbD4bDf7x8U6vV6g8Egz3MAKBFCDg8P5/P52dnZnULz+XyxWIxGIwDgnI8LjUajy+9kMplOp8aYEgBEUXRwcJDnudY6SZI0Tfv9frvdDoLAGDObzcbj8cnJyXQ6HY/HR0dHFh4MBsvKGGMbLwxDOwLHcWy2OI57vd7p6enx8bHlJ5PJbDYbDodSyiVMKRWF7CSEEI1Gw/aMMQ7DEGMMAPYfLhSGYcke0lqnaSqlZIxhjJVSUkqMsb2afCEAIITEcUwIWWVO07Tb7SqlbLUoitrtNsaYUprnuTHmclXGGLtXSukKllIKIaSU38LGGCllHMdCCMaY1jrLMqXUVzAhRGttYfsqbIf2Cs65fSG4kLWWA2OMdTqdLMuEEJRSm/nLqHby4kKX1goGAM/zlFLW1lpfknYLlmeM2eRfwfu+X1m/FndU3JFxRzIuSNEYIYQXRRkXsJz8qokVTCn1fR9tXbu+dmXnxjrs1XjLYfu3Pi+HUgb7IvSI3wS/yYMmD1uEXGTGGLvHv99Y/OV8/4e7+PPmj3+j2RMCuCAZDW/XfnhWuf+u9utr9Nub9XtvN8//xUISwMvKlNLr56+uPvzoPH5fe/T+6oMPm7/8TwksjeV8fXT29Lu7/zl3X6CfX26e/1P96TkRHUrgEyle6KQ+J+BZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c7957f47104579023448d5a2a247479/e4706/Android_Wizard_Installation_8001_6001_d04.avif 230w", "/en/static/1c7957f47104579023448d5a2a247479/d1af7/Android_Wizard_Installation_8001_6001_d04.avif 460w", "/en/static/1c7957f47104579023448d5a2a247479/7f308/Android_Wizard_Installation_8001_6001_d04.avif 920w", "/en/static/1c7957f47104579023448d5a2a247479/e1c99/Android_Wizard_Installation_8001_6001_d04.avif 1380w", "/en/static/1c7957f47104579023448d5a2a247479/6e1e4/Android_Wizard_Installation_8001_6001_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c7957f47104579023448d5a2a247479/a0b58/Android_Wizard_Installation_8001_6001_d04.webp 230w", "/en/static/1c7957f47104579023448d5a2a247479/bc10c/Android_Wizard_Installation_8001_6001_d04.webp 460w", "/en/static/1c7957f47104579023448d5a2a247479/966d8/Android_Wizard_Installation_8001_6001_d04.webp 920w", "/en/static/1c7957f47104579023448d5a2a247479/445df/Android_Wizard_Installation_8001_6001_d04.webp 1380w", "/en/static/1c7957f47104579023448d5a2a247479/87a8c/Android_Wizard_Installation_8001_6001_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c7957f47104579023448d5a2a247479/81c8e/Android_Wizard_Installation_8001_6001_d04.png 230w", "/en/static/1c7957f47104579023448d5a2a247479/08a84/Android_Wizard_Installation_8001_6001_d04.png 460w", "/en/static/1c7957f47104579023448d5a2a247479/c0255/Android_Wizard_Installation_8001_6001_d04.png 920w", "/en/static/1c7957f47104579023448d5a2a247479/b1001/Android_Wizard_Installation_8001_6001_d04.png 1380w", "/en/static/1c7957f47104579023448d5a2a247479/81315/Android_Wizard_Installation_8001_6001_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c7957f47104579023448d5a2a247479/c0255/Android_Wizard_Installation_8001_6001_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2754ed57be06cbb7f90ee7d8ea168721/81315/Android_Wizard_Installation_8001_6001_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzUlEQVQ4y42Sy27bRhSGueomkr0ILEN2RMkWJcohOZz7DClyJkOR6iK60LbgIEEuDQIUcPsMXnhtIMgmQF7Cq3ZX9DXaXZFtHqJNIFI2nE3SDweDwY/zz+XHsVzXHQwGrVar0Wg0m83t7W3btjt2p73XbncORqOR4zi7u7tbW1vNZrPRaPR6Pdu22+12tz+yXNd1HMcYAyHsdruHh4fdXq9rd7r94T7+ceiOBoNBmqZRFB1U2HbXtu3ewcEeeWx5nocQ4hWMMVxBCMEYe54XVEgpGWOU0lqnlAZB4PuBRSk1xkyn06IosiwrimIymdQrpZQxRghJ0zTLsjzPjTH1RgghOLW01mdnZ/P5/KSiLMvT09OyLFerlVJKCDGbzRaLRRzHnucBAKqn+CEiPntkjcfjk5OT5XJZluVisag3s9lsuVxqrYUQ9UOSJJFSRjdIGYlYWVJKpdR4PM7z/Pj4eLVaTafTKIqUUnEcc86VUlrrLMuEEIQQWkEoJTyyAAC+7wMA6jAwxgEAQSVCCKMoms3ni8WymE4hQrAiDAEiAsjMQpXEOTfGSCk4owhCBMNaX5+IwnEcJ0mitVZpqrU2xjCCKcFWdVIohEjTlHPBYh1SGWK+uQSigMZyrGI1ifVEJjpKTaINYAlAZGOWUhKCCZNEzx/qs6P48doIIUQYsMRVp/3i50H+eli87udvnEfPApGFmG3M9ZAQjBHlGCEYBnADxp6z8+ufP1x97rz7d/j+v8bV53sXnwCNUOhbNy24upwIzimlt9nU+TxMl27+03DyYjh56U5ejczTEBEIQ+u2ByFUDyDCGH4NDo6w52BvsCl/WH9qY0bVPK+hBGOyzvsuCIeI3K1a3pgZBhwFOAwwBAz6ODiC/4PaHO68uW5dfHQv/z66/Kt18c/98z82aX/bjNZA+8nb/fPfD8+v+79c75//9uD5B4Qg+h5fAM/26XAhjJzlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2754ed57be06cbb7f90ee7d8ea168721/e4706/Android_Wizard_Installation_8001_6001_d05.avif 230w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/d1af7/Android_Wizard_Installation_8001_6001_d05.avif 460w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/7f308/Android_Wizard_Installation_8001_6001_d05.avif 920w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/e1c99/Android_Wizard_Installation_8001_6001_d05.avif 1380w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/6e1e4/Android_Wizard_Installation_8001_6001_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2754ed57be06cbb7f90ee7d8ea168721/a0b58/Android_Wizard_Installation_8001_6001_d05.webp 230w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/bc10c/Android_Wizard_Installation_8001_6001_d05.webp 460w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/966d8/Android_Wizard_Installation_8001_6001_d05.webp 920w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/445df/Android_Wizard_Installation_8001_6001_d05.webp 1380w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/87a8c/Android_Wizard_Installation_8001_6001_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2754ed57be06cbb7f90ee7d8ea168721/81c8e/Android_Wizard_Installation_8001_6001_d05.png 230w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/08a84/Android_Wizard_Installation_8001_6001_d05.png 460w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/c0255/Android_Wizard_Installation_8001_6001_d05.png 920w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/b1001/Android_Wizard_Installation_8001_6001_d05.png 1380w", "/en/static/2754ed57be06cbb7f90ee7d8ea168721/81315/Android_Wizard_Installation_8001_6001_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2754ed57be06cbb7f90ee7d8ea168721/c0255/Android_Wizard_Installation_8001_6001_d05.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9da100ed28cfb609b16d60200722e00/81315/Android_Wizard_Installation_8001_6001_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACg0lEQVQ4y43RTWsTQRgH8D1HKBRsm8buJtmYJvs2r7szmX2b3U1KPCjEJLQUKh6aa6v1ZA968O5Z0JsHRb34BRR6kILfoDfBLyI7a0MRrP6Y07z+53m0fr/f7XbX19drtdoNxTRNXdcbjYbeNLvdrmVZa2try9VWq6XrW/XNervT1Xq9XqfTybIMY6zrulrTm4bRNG9v4ju9vmWaZpqmhBBd19vttm4Y5YZWe5Pe1WwFABAEAcaYKNWk7bq2bTuOwxgjhFSrGGNKqecB23G1IAi4IqVM0zTLsjzPOeeMMV8JgkBKmSSJVKo9nHPf97UkSXKlKIrpdDqfz0ej0VBJlNlsNplM7iu7u7t7e3uz2Ww+n4/HY40xliQJYwxCWD0FIfQU13UZY1LKoiiiKCKEVEGqbQAALQzDKie8hBCCEFJKB4NBGIZVijiJIAQIAghKGOMwDDUp5Xg8Xt5XoZRyzoUQYRiOym8URS6rWvqUUEIYY1Eca5zzUKkqeRWEqIzh2fWHb2unP7eeXRjPL1ZOf9x8fI4og8DToijK81wIUQX+U3mH15q+2Fh8Nhbvmov3G4efbh28hthHCGpCiOFwmKbp8kGEEKU0y/MklQAAhDFxt6ndJpaJLZPaJnU6SGUqC5ZlWRzH5BIt+YygAHqXAQjC9Mog1WzZKiHEQIiA8YAxxjimfoAxGE/se/sIAvR3WhzHRVHs5MkoFUUa7mSxCHCKLbh4uv3kJfLc6w6XXXOd+v6rlaPzxvGXxvHX1aNv9cOPCADs2gjh6w6X9URw68Gb1UffmydnxsnZ6vF5/fADJBT9i/a7I55F3G3k9JHTJ24Pe330H7SrPUVYDYSvT7s8/AuAKeRRVIpe2wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9da100ed28cfb609b16d60200722e00/e4706/Android_Wizard_Installation_8001_6001_d06.avif 230w", "/en/static/a9da100ed28cfb609b16d60200722e00/d1af7/Android_Wizard_Installation_8001_6001_d06.avif 460w", "/en/static/a9da100ed28cfb609b16d60200722e00/7f308/Android_Wizard_Installation_8001_6001_d06.avif 920w", "/en/static/a9da100ed28cfb609b16d60200722e00/e1c99/Android_Wizard_Installation_8001_6001_d06.avif 1380w", "/en/static/a9da100ed28cfb609b16d60200722e00/6e1e4/Android_Wizard_Installation_8001_6001_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9da100ed28cfb609b16d60200722e00/a0b58/Android_Wizard_Installation_8001_6001_d06.webp 230w", "/en/static/a9da100ed28cfb609b16d60200722e00/bc10c/Android_Wizard_Installation_8001_6001_d06.webp 460w", "/en/static/a9da100ed28cfb609b16d60200722e00/966d8/Android_Wizard_Installation_8001_6001_d06.webp 920w", "/en/static/a9da100ed28cfb609b16d60200722e00/445df/Android_Wizard_Installation_8001_6001_d06.webp 1380w", "/en/static/a9da100ed28cfb609b16d60200722e00/87a8c/Android_Wizard_Installation_8001_6001_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9da100ed28cfb609b16d60200722e00/81c8e/Android_Wizard_Installation_8001_6001_d06.png 230w", "/en/static/a9da100ed28cfb609b16d60200722e00/08a84/Android_Wizard_Installation_8001_6001_d06.png 460w", "/en/static/a9da100ed28cfb609b16d60200722e00/c0255/Android_Wizard_Installation_8001_6001_d06.png 920w", "/en/static/a9da100ed28cfb609b16d60200722e00/b1001/Android_Wizard_Installation_8001_6001_d06.png 1380w", "/en/static/a9da100ed28cfb609b16d60200722e00/81315/Android_Wizard_Installation_8001_6001_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9da100ed28cfb609b16d60200722e00/c0255/Android_Wizard_Installation_8001_6001_d06.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Please choose a name for your camera.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/8efc2/Android_Wizard_Installation_8001_6001_d07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD2ElEQVRIx+2UbW/bVBTHXTpWSovQEJWSNKGJk9hJbN8nP8WO44fYju04aZNITVTaBo23Q+IL8HKbVKCCDzAVeDE+B+JjTauCbLdd0m1IiFdI/HRl3XPu+fvec861qd3d3clkoqrqzs4OTdNbW1sbGxvUCpubm6VSqdlsMgyTy+c+/eyTfC4v8Hy5XKZKpVI5pVQq7e3tPUrJrVMsFsvl8v7+fj4hV8jnihWmeEBTDMOYpknTNMuy4/F4NpvFcVyr1arrVCoVmqZvrVree1I0vqRomoYQZmumaXqe5/s+TdPldSopd8a+fFiEDtVsNqvVaj3l4ODgi5R6vc6yLJPCpjAMs+pkatVmg6UwxkEQhGHoeZ5t247jRFEUhqFt25nH930vJY7jTqfjeV4QhK7rEkISsa7rQRBEUWTbtuu6vu/3er1+vx9FURzHvV7PNE3Xde82sCzL8zyMMYUQghAKKQCAbMJxnOd5g8FgNBpFUUQI4Xk+CYAQpAiCgBBKdr6zBUGAEJIUhBBeAQGAEUICwBBiCBMT40TsOE6Woed5hmEkoSskr5MkzbFlTdN6jmIYatfQHAcTQhFCgiAYDoezlMPDQ0mSIISrYlGWzXjQtkxzODD6vhH0zeGAiGKyM5/CcVyr1cqSwesgiADHIQEAjoOCAHge8DxGKNlZ0zRFUWRZ1nVdVVVZltelSJQkpdORVFXp6FJbVXRdbrdx1qrpdHp2dnZ6ejqbzRaLxcnJyerJIQCyrtvjIyPoW0ej5Hk46kYhkeVETG5BCBFC8NvcBmTTOzd1V1uYks3flqNUd8+T5DyZTBaLRVZtWZZXS/2m4KpqjeJuFFqjoTUaar20VQghVVV1Xdc0rdvtiqKI3gXGWJQkIkmiLIuKTNKwm1Zxt/A8/85jJ7kBgACAggABQBDe5DydTo+Pj4+OjsaTyTj5JbUBAPePrSjdKOz4XjcKjaDftqybVjmO47puz3Vdx3QtTcE85lkiNN4MnhVhq62Jqq6o3a5qmZLWRtlXlV4wQeBbDdmpmTPWmjXsecOe1815zZyz6bxhzRhz3tJCKPAQgKyo1M3JiCRydOGr3z78Ybn99NVHz64/fn6du3ydu7zeeX699ex6++mrh98vP//mDyw0ESaZ6EYMsSi2Krmvf//g5+XDi9cPLpbbPy7FX5f4l+XO5fLBReLc/Gn56Ns/UzFeEyNMiNCohU/yj18Wzl8UFlf586u902Tkz68Ki6vC+Yv845eV8XcY8AjdE2e9FJqEqxKulg2Rq4orZrLEs6tdoO5dhSSfvxv4/eJ/yP/i/46Y/Av+AihVv7wypyZmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/e4706/Android_Wizard_Installation_8001_6001_d07.avif 230w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/d1af7/Android_Wizard_Installation_8001_6001_d07.avif 460w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/ec170/Android_Wizard_Installation_8001_6001_d07.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/a0b58/Android_Wizard_Installation_8001_6001_d07.webp 230w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/bc10c/Android_Wizard_Installation_8001_6001_d07.webp 460w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/712de/Android_Wizard_Installation_8001_6001_d07.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/81c8e/Android_Wizard_Installation_8001_6001_d07.png 230w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/08a84/Android_Wizard_Installation_8001_6001_d07.png 460w", "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/8efc2/Android_Wizard_Installation_8001_6001_d07.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/46a5d33b0018fe2fe14d2bcf3aa8fc5c/8efc2/Android_Wizard_Installation_8001_6001_d07.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      